.chart-center {
    // width: 100vh;
    // height: 100vw;
    width: 0;
    height: 0;
    position: absolute;
    overflow: visible;
}

.chart-item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}

.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 1;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

body.hidden-scroll {
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
}