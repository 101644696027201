.profile {

    .firstname {
        max-width: 130px;
        overflow: hidden;
    }

    .lastname {
        text-transform: uppercase;
        font-size: 22px;
    }

    .position {
        font-size: 18px;
        font-weight: normal;
        padding-top: 7px;

    }

    .hexagon-img {
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        width: 190px;
        height: 220px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -85px;
        left: 52px;
    }



    .hexagon-image {
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        width: 190px;
        height: 220px;
        position: absolute;
        bottom: -85px;
        left: 52px;
        img {
            width: 190px;
            height: 220px;
            object-fit: cover;
        }
    }
}