.group {
  .title {
    text-transform: uppercase;
    text-align: center;
  }

  .subTitle {
    font-size: 40px;
    text-align: center;
  }

  .hexagon-content {
    display: flex;
    align-items: center;
    top: 0;
    font-size: 50px;
    padding: 0 5%;
    justify-content: center;
    height: 100%;
    line-height: 50px;
  }

}