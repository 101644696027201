.hexagon {
    position: relative;
    width: 300px;
    height: 173.21px;
    margin: 86.60px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    border-left: solid 3px #000;
    border-right: solid 3px #000;

    &:before {
        background-color: #ffffff;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 293px;
        height: 173.21px;
        z-index: 2;
        border-radius: 5px;
    }

    &.highlight {
        border-width: 6px;
        width: 301px;

        &:before {
            top: 2px;
            left: 0;
            width: 289px;
            height: 169.21px;
        }

        span {
            width: 288px;
        }

        .before {
            border-width: 8.4852px;
            transform: scaleY(0.576) rotate(-45deg);
            left: 37.934px;
            width: 213.13px;
        }

        .after {
            border-width: 8.4852px;
            transform: scaleY(0.576) rotate(-45deg);
            left: 37.934px;
            width: 213.13px;

        }

        &.profile .hexagon-img, &.profile .hexagon-image {
            bottom: -82px;
            left: 49px;
        }

        .hexagon-content {
            width: 290px;
        }

    }

    .link {
        position: absolute;
        top: -70px;
        left: -7px;
        width: 310px;
        height: 310px;
        border-radius: 300px;
        z-index: 10;
    }

    .before,
    .after {
        background-color: #ffffff;
        content: "";
        position: absolute;
        z-index: 1;
        width: 212.13px;
        height: 212.13px;
        -webkit-transform: scaleY(0.5774) rotate(-45deg);
        -ms-transform: scaleY(0.5774) rotate(-45deg);
        transform: scaleY(0.5774) rotate(-45deg);
        left: 40.9340px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    }

    .before {
        top: -106.0660px;
        border-top: solid 4.2426px #000;
        border-right: solid 4.2426px #000;
        z-index: 1;
    }

    .after {
        bottom: -106.0660px;
        border-bottom: solid 4.2426px #000;
        border-left: solid 4.2426px #000;
        z-index: 1;
    }

    span {
        display: block;
        position: absolute;
        top: 1.7320508075688772px;
        left: 0;
        width: 294px;
        height: 169.7410px;
        z-index: 2;
        background: inherit;
    }

    .hexagon-content {
        z-index: 3;
        position: absolute;
        left: 0;
        top: -45px;
        width: 294px;
        height: 216.741px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
    }

    .anchor {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        z-index: 5;

        .anchor-middle {
            position: absolute;
            top: 0;
            left: 0;
            overflow: visible;
            width: 0;
            height: 0;
            transform-origin: center;
            transform: translate(15px, 15px);
            z-index: -1;

            .line {
                height: 5px;
                background-color: #bfbfbf;
                position: absolute;
                width: 0;
                top: 0;
                left: 0;
                transform-origin: left;
                z-index: -1;
            }
        }

        &.end {
            background-color: #bfbfbf;
        }

        &.start {
            // background-color: white;
            z-index: -1;
        }

        &.anchor-1 {
            top: -90px;
            left: 132px;

            &.start {
                border-top: 3px solid #bfbfbf;
                top: -110px;
            }

            .line {
                transform: rotate(-90deg) translate(20px, 0px);
            }
        }

        &.anchor-2 {
            top: -58px;
            left: 207px;
            transform: rotate(30deg);

            &.start {
                border-top: 3px solid #bfbfbf;
                top: -72px;
                left: 212px;
            }

            .line {
                transform: rotate(-90.3deg) translate(20px, 0px);
            }
        }

        &.anchor-3 {
            top: -10px;
            left: 273px;
            transform: rotate(60deg);

            &.start {
                border-top: 3px solid #bfbfbf;
                top: -17px;
                left: 288px;
            }

            .line {
                transform: rotate(-90.4deg) translate(20px, 0px);
            }
        }

        &.anchor-4 {
            top: 72px;
            left: 281px;

            &.start {
                border-right: 3px solid #bfbfbf;
                left: 294px;
            }

            .line {
                transform: translate(15px, -2px);
            }
        }

        &.anchor-5 {
            top: 153px;
            left: 274px;
            transform: rotate(-60deg);

            &.start {
                border-bottom: 3px solid #bfbfbf;
                top: 161px;
                left: 288px;
            }

            .line {
                transform: rotate(90.4deg) translate(10px, 0px);
            }
        }

        &.anchor-6 {
            top: 200px;
            left: 204px;
            transform: rotate(-30deg);

            &.start {
                border-bottom: 3px solid #bfbfbf;
                top: 212px;
                left: 213px;
            }

            .line {
                transform: rotate(90.4deg) translate(10px, 0px);
            }
        }

        &.anchor-7 {
            top: 230px;
            left: 132px;

            &.start {
                border-bottom: 3px solid #bfbfbf;
                top: 250px;
            }

            .line {
                transform: rotate(90deg) translate(10px, 0px);
            }
        }

        &.anchor-8 {
            top: 199px;
            left: 57px;
            transform: rotate(30deg);

            &.start {
                border-bottom: 3px solid #bfbfbf;
                top: 212px;
                left: 52px;
            }

            .line {
                transform: rotate(89.6deg) translate(10px, 0px);
            }
        }

        &.anchor-9 {
            top: 152px;
            left: -9px;
            transform: rotate(60deg);

            &.start {
                border-bottom: 3px solid #bfbfbf;
                top: 161px;
                left: -24px;
            }

            .line {
                transform: rotate(89.6deg) translate(10px, 0px);
            }
        }

        &.anchor-10 {
            top: 72px;
            left: -17px;

            &.start {
                border-left: 3px solid #bfbfbf;
                left: -30px;
            }

            .line {
                transform: rotate(-180deg) translate(15px, 2px);
            }
        }

        &.anchor-11 {
            top: -10px;
            left: -9px;
            transform: rotate(-60deg);

            &.start {
                border-top: 3px solid #bfbfbf;
                top: -18px;
                left: -24px;
            }

            .line {
                transform: rotate(-89.6deg) translate(20px, 0px);
            }
        }

        &.anchor-12 {
            top: -57px;
            left: 58px;
            transform: rotate(-30deg);

            &.start {
                border-top: 3px solid #bfbfbf;
                top: -68px;
                left: 51px;

            }

            .line {
                transform: rotate(-89.7deg) translate(20px, 0px);
            }
        }
    }
}