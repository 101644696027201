.agency {

    .hexagon-content {
        display: flex;
        align-items: center;
        top: 0;
        font-size: 50px;
        padding: 0 5%;
        justify-content: center;
        height: 100%;
    }

    .hexagon-img {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .hexagon-image {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}