.separator {
    height: 3px;
    background-color: #bfbfbf;
    min-width: 300px;
    transform-origin: center;
    &:before, &:after {
        display: block;
        height: 15px;
        width: 15px;
        background-color: #bfbfbf;
        content: " ";
        position: absolute;
        top: -6px;
    }
    &:before {
        left: 0px;
    }
    &:after {
        right: 0px;
    }
}